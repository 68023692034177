import React, { useEffect, useState } from 'react';
import { decode } from 'js-base64';
import { LinkAlert } from 'components/dialog';

const RedirectPage = ({ location }) => {
  const [showLinkAlert, setShowLinkAlert] = useState(false);
  const [link, setLink] = useState('');
  // 取得user agent，並檢查是否為QC-APP
  const userAgent = typeof window !== 'undefined' ? navigator.userAgent : '';
  const isQCApp =
    userAgent.indexOf('iOS-JSP-APP') > -1 ||
    userAgent.indexOf('Android-JSP-APP') > -1;
  // 取得網址所有的query
  const query = location.search;

  useEffect(() => {
    let url = process.env.GATSBY_APP_SCHEME_URL;
    if (isQCApp) {
      // 取得query中的link
      const params = new URLSearchParams(location.search);
      const link = params.get('link');
      if (link) {
        // 將link進行base64解碼
        url = decode(link);
      } else {
        return;
      }
    } else {
      url = `${url}${query}`;
    }

    console.log('url', url);
    // 導轉網址
    if (typeof window !== 'undefined') window.location.href = url;
    setLink(url);
    setTimeout(() => {
      setShowLinkAlert(true);
    }, 1500);
  }, []);

  return (
    <>
      <LinkAlert
        key="LinkAlert"
        show={showLinkAlert}
        title="開啟家速配APP"
        txtRight="確定"
        txtLeft="取消"
        onLeft={() => {
          setShowLinkAlert(false);
        }}
        onRight={() => {
          setShowLinkAlert(false);
        }}
        linkUrl={link}
      />
    </>
  );
};

export default RedirectPage;
